import Badges from '../components/Badges/Badges';
import Carousel from '../components/Carousel/Carousel';
import Footer from '../components/Footer/Footer';
import Mint from '../components/Mint/Mint';
import Rewards from '../components/Rewards/Rewards';
import Sidebar from '../components/Sidebar/Sidebar';
import styles from '../components/Sidebar/Sidebar.module.scss';
import Topbar from '../components/Topbar/Topbar';

export default function HomePage() {
  return (
    <>
      <Topbar />
      <section className={styles.center}>
        <div className={styles.center__wrapper}>
          <Sidebar />
          <main className={styles.main}>
            <div className={styles.main__flex}>
              <Mint />
              <Rewards />
            </div>
            <div className={styles.main__flex}>
              {/* <HeroSection /> */}
              <Carousel />
              <Badges />
            </div>
          </main>
        </div>
      </section>
      <Footer />
    </>
  );
}
