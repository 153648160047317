import Footer from '../components/Footer/Footer';
import Growth from '../components/Growth/Growth';
import Investments from '../components/Investments/Investments';
import Pagination from '../components/Pagination/Pagination';
import Sidebar from '../components/Sidebar/Sidebar';
import styles from '../components/Sidebar/Sidebar.module.scss';
import Topbar from '../components/Topbar/Topbar';
import Tracker from '../components/Tracker/Tracker';

export default function PortfolioPage() {
  return (
    <>
      <Topbar />
      <section className={styles.center}>
        <div className={styles.center__wrapper}>
          <Sidebar />
          <main className={styles.main}>
            <div className={styles.main__flex}>
              <Investments />
              <Growth />
            </div>
            {/* TODO: Implement tokens */}
            <div className={styles.main__flex}>
              <Tracker />
            </div>
            <Pagination />
          </main>
        </div>
      </section>
      <Footer />
    </>
  );
}
