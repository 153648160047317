import { Icon } from '@iconify/react';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as InfoHexagonIcon } from '../../assets/images/info-hexagon-icon.svg';
import styles from './Carousel.module.scss';

export default function Carousel() {
  const carouselRef = useRef(null);
  const data = [
    { image_source: 'https://placekitten.com/150/200', name: 'AI Generatored NFT Cat 1' },
    { image_source: 'https://placekitten.com/150/202', name: 'AI Generatored NFT Cat 2' },
    { image_source: 'https://placekitten.com/150/204', name: 'AI Generatored NFT Cat 3' },
    { image_source: 'https://placekitten.com/150/206', name: 'AI Generatored NFT Cat 4' },
    { image_source: 'https://placekitten.com/150/208', name: 'AI Generatored NFT Cat 5' },
    { image_source: 'https://placekitten.com/150/210', name: 'AI Generatored NFT Cat 6' },
    { image_source: 'https://placekitten.com/150/212', name: 'AI Generatored NFT Cat 7' },
    { image_source: 'https://placekitten.com/150/214', name: 'AI Generatored NFT Cat 8' },
    { image_source: 'https://placekitten.com/150/216', name: 'AI Generatored NFT Cat 9' },
    { image_source: 'https://placekitten.com/150/218', name: 'AI Generatored NFT Cat 10' },
  ];
  const [currentIndex, setIndex] = useState(0);
  const [currentName, setName] = useState(data[currentIndex].name);
  const [isModalOpen, setModalState] = useState(false);

  useEffect(() => {
    const carousel = [...carouselRef.current.children];
    for (let i = 0; i < carousel.length; i++) {
      const image = carousel[i];
      if (i === currentIndex) {
        image.setAttribute('data-position', 'current');
      } else if (i < currentIndex) {
        image.setAttribute('data-position', 'previous');
        if (i === currentIndex - 1) {
          image.style.zIndex = '1';
        }
      } else if (i > currentIndex) {
        image.setAttribute('data-position', 'next');
        if (i === currentIndex + 1) {
          image.style.zIndex = '1';
        }
      }
    }
  }, [currentIndex]);

  function previousIndex() {
    const carousel = [...carouselRef.current.children];
    if (currentIndex !== 0) {
      const newIndex = currentIndex - 1;
      setName(data[newIndex].name);
      setIndex(newIndex);
      carousel[newIndex + 2].style.zIndex = null;
      carousel[newIndex].style.zIndex = null;
      carousel[newIndex - 1].style.zIndex = '1';
    }
  }

  function nextIndex() {
    const carousel = [...carouselRef.current.children];
    if (currentIndex < carousel.length - 1) {
      const newIndex = currentIndex + 1;
      setName(data[newIndex].name);
      setIndex(newIndex);
      carousel[newIndex - 2].style.zIndex = null;
      carousel[newIndex].style.zIndex = null;
      carousel[newIndex + 1].style.zIndex = '1';
    }
  }

  return (
    <>
      <div className={styles.base}>
        <div className={styles.wrapper}>

          <div className={styles.top}>

            <div className={styles.topLeft}>
              <span>Your NFTs</span>
              <button onClick={() => setModalState(true)}>
                <InfoHexagonIcon />
              </button>
            </div>

            <div className={styles.topRight}>
              <p>Total fees <span style={{ color: '#ffffff' }}>12 Matic</span> due in <span style={{ color: '#86ffbe' }}>28 days</span></p>
              <button>Pay now</button>
              <button>
                <Icon color="#ffffff" icon="fluent:filter-16-filled" />
              </button>
            </div>
          </div>

          <div className={styles.carousel__container}>
            <div className={styles.carousel__wrapper}>
              <button onClick={previousIndex}>
                <Icon color="#ffffff" icon="akar-icons:chevron-left" />
              </button>
              <div className={styles.carousel} ref={carouselRef}>
                {data.map((datum, index) => {
                  return (
                    <img alt={datum.name} key={index} src={datum.image_source} />
                  );
                })}
              </div>
              <button onClick={nextIndex}>
                <Icon color="#ffffff" icon="akar-icons:chevron-right" />
              </button>
            </div>
            <p className={styles.carousel__name}>{currentName}</p>
          </div>

        </div>

        {isModalOpen &&
          <div className={styles.modal}>
            <button className={styles.modal__close} onClick={() => setModalState(false)} >
              <Icon color="#72799B" icon="ep:close" height={24} />
            </button>
            <p className={styles.modal__heading}>Can I sell my NFTs?</p>
            <p className={styles.modal__body}>All Hive NFTs can be sold or auctioned on the HexagOn marketplace.</p>
            <p className={styles.modal__heading}>Is there a fee to sell my NFTs?</p>
            <p className={styles.modal__body}>As Hive NFTs, unlike $HNY tokens, will not have a sale tax, each trade would provide a royalty of 7.5% to the protocol.</p>
          </div>
        }

      </div>
    </>
  );
}
