/* eslint-disable jsx-a11y/anchor-is-valid */
import { Icon } from '@iconify/react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import styles from './Sidebar.module.scss';

export default function Sidebar() {
  return (
    <aside className={styles.base}>
      <p className={styles.heading}>Dashboard</p>
      <Link icon="charm:home" path="/" text="Home" />
      <Link icon="charm:chart-line" path="/portfolio" text="Investment portfolio" />
      <Link icon="charm:book" path="/white-paper" text="White paper" />
      <Link icon="charm:person" path="/kyc" text="KYC" />
      <Link icon="charm:shield-tick" path="/audit" text="Audit" />
      <p className={styles.heading}>Administration</p>
      <Link icon="charm:link" path="/connect" text="Connect app" />
    </aside>
  );
}

function Link({ icon, path, text }) {
  return (
    <NavLink className={({ isActive }) => clsx(styles.link, { [styles.active]: isActive })} to={path}>
      <Icon icon={icon} />
      <span>{text}</span>
      <div></div>
    </NavLink>
  );
}
