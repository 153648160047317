import { Icon } from '@iconify/react';
import clsx from 'clsx';
import styles from './Pagination.module.scss';

export default function Pagination() {


  return (
    <div className={styles.base}>
      <div className={styles.pagination}>
        <button className={styles.button}>
          <Icon icon="akar-icons:chevron-left" />
        </button>
        <div className={styles.numbers}>
          <div className={clsx(styles.number, { [styles.active]: true })}>1</div>
          <div className={styles.number}>2</div>
          <div className={styles.number}>3</div>
          <div className={styles.number}>4</div>
          <div className={styles.number}>5</div>
        </div>
        <button className={styles.button}>
          <Icon icon="akar-icons:chevron-right" />
        </button>
      </div>
    </div>
  );
}
