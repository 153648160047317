import { Icon } from '@iconify/react';
import { Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useState } from 'react';
import { ReactComponent as ButtonHexagonIcon } from '../../assets/images/button-hex.svg';
import { ReactComponent as InfoHexagonIcon } from '../../assets/images/info-hexagon-icon.svg';
import styles from './Mint.module.scss';

export default function Mint() {
  const SLIDER_DEFAULT_VALUE = 3;
  const [sliderValue, setSliderValue] = useState(SLIDER_DEFAULT_VALUE);
  const [isModalOpen, setModalState] = useState(false);

  return (
    <>
      <div className={styles.base}>
        <div className={styles.subbase3}></div>
        <div className={styles.subbase2}></div>
        <div className={styles.subbase1}>
          <div className={styles.top}>
            <div className={styles.flex}>
              <p className={styles.heading}>Mint</p>
              <button onClick={() => setModalState(true)}>
                <InfoHexagonIcon />
              </button>
            </div>
            <p className={styles.subheading}>Mint up to 5 NFTs at once</p>
          </div>

          <div className={styles.slider}>
            <p className={styles.slider__text}>Mint up to <span>{sliderValue}</span> NFTs for <span>{sliderValue * 10} HNY</span></p>
            <HiveSlider
              aria-label="Default"
              defaultValue={sliderValue}
              max={5}
              min={1}
              onChange={(e) => setSliderValue(e.target.value)}
              step={1}
            />
            <div className={styles.slider__label}>
              <span className={clsx({ [styles.slider__labelActive]: sliderValue === 1 })}>1</span>
              <span className={clsx({ [styles.slider__labelActive]: sliderValue === 2 })}>2</span>
              <span className={clsx({ [styles.slider__labelActive]: sliderValue === 3 })}>3</span>
              <span className={clsx({ [styles.slider__labelActive]: sliderValue === 4 })}>4</span>
              <span className={clsx({ [styles.slider__labelActive]: sliderValue === 5 })}>5</span>
            </div>
          </div>

          <div className={styles.selection}>
            <p className={styles.selection__label}>Mint up to 5 NFTs at once</p>
            <div className={styles.selection__buttons}>
              <button className={styles.selection__button}>
                <div>
                  <ButtonHexagonIcon />
                  <span className={styles.selection__buttonText}>Compound rewards</span>
                </div>
                <span className={styles.selection__buttonNumber}>16.5</span>
              </button>
              <button className={styles.selection__button}>
                <div>
                  <ButtonHexagonIcon />
                  <span className={styles.selection__buttonText}>Wallet balance</span>
                </div>
                <span className={styles.selection__buttonNumber}>13.5</span>
              </button>
            </div>
          </div>

          <button className={styles.mint}>
            <div className={styles.mint__wrapper}>
              <div className={styles.mint__wrapper2}>
                <span>Mint</span>
              </div>
            </div>
          </button>
        </div>

        {isModalOpen &&
          <div className={styles.modal}>
            <button className={styles.modal__close} onClick={() => setModalState(false)} >
              <Icon color="#72799B" icon="ep:close" height={24} />
            </button>
            <p className={styles.modal__heading}>How does minting work?</p>
            <p className={styles.modal__body}>There are three different tiers of NFTs, all of which can be obtained by minting with $HNY tokens. Each tier offers important stylistic differences as well as differences in yield.</p>
            <p className={styles.modal__heading}>How much does it cost to mint an NFT?</p>
            <p className={styles.modal__body}>There are three different tiers of NFTs, all of which can be obtained by minting with $HNY tokens. Each tier offers important stylistic differences as well as differences in yield.</p>
          </div>
        }
      </div>
    </>
  );
}

// TODO: Update color scheme
const HiveBoxShadow = '0 3px 1px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.13), 0 0 0 1px rgba(0,0,0,0.02)';

const HiveSlider = styled(Slider)(() => ({
  color: '#ffb629',
  height: 1.5,
  padding: '4px 0 12px',
  width: 'calc(100% - 8px)',
  '& .MuiSlider-thumb': {
    height: 9,
    width: 9,
    backgroundColor: '#ffb629',
    boxShadow: HiveBoxShadow,
    '&:focus, &:hover, &.Mui-active': {
      boxShadow:
        '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: HiveBoxShadow,
      },
    },
  },
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-rail': {
    opacity: 1,
    backgroundColor: '#ffb629',
  },
}));
