import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import styles from './Topbar.module.scss';

export default function Tobar() {
  function addToken() {
    console.log('enter');
  }

  function connectWallet() { }

  return (
    <div className={styles.base}>
      <div className={styles.wrapper}>
        <div className={styles.brand}>
          <Link to="/">
            <img alt="Hive Investments logo" className={styles.logo} draggable={false} src={Logo} />
          </Link>
        </div>
        <div className={styles.buttons}>
          {/* TODO: Implement add tokens */}
          <button className={styles.button} onClick={addToken}>Add token</button>
          {/* TODO: Implement connect wallet */}
          <button className={styles.button} onClick={connectWallet}>Connect wallet</button>
        </div>
      </div>
    </div>
  );
}
