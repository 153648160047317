import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import PortfolioPage from './pages/PortfolioPage';

export default function App() {
  return (
    <div id="subroot">
      <BrowserRouter>
        <Routes>
          {/* <Route path="/"> */}
          <Route element={<HomePage />} index />
          <Route element={<PortfolioPage />} path="/portfolio" />
          <Route element={<HomePage />} path="/white-paper" />
          <Route element={<HomePage />} path="/kyc" />
          <Route element={<HomePage />} path="/audit" />
          <Route element={<HomePage />} path="/connect" />

          {/* 404 page */}
          <Route path="*" element={<Navigate replace to="/" />} />
          {/* </Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}
