import { Icon } from '@iconify/react';
import { useState } from 'react';
import { ReactComponent as InfoHexagonIcon } from '../../assets/images/info-hexagon-icon.svg';
import styles from './Rewards.module.scss';


export default function Rewards() {
  const [isModalOpen, setModalState] = useState(false);
  const balance = 1650;
  const data = [{
    type: 'Workers',
    quantity: 10,
    dailyRewards: 0.5,
    currentRewards: 1.5,
  }, {
    type: 'Guardians',
    quantity: 2,
    dailyRewards: 0.2,
    currentRewards: 14,
  }, {
    type: 'Queens',
    quantity: 1,
    dailyRewards: 0.2,
    currentRewards: 1,
  }];

  return (
    <>
      <div className={styles.base}>
        <div className={styles.subbase3}></div>
        <div className={styles.subbase2}></div>
        <div className={styles.subbase1}>

          <div className={styles.top}>
            <div className={styles.flex}>
              <p className={styles.heading}>Rewards</p>
              <button onClick={() => setModalState(true)}>
                <InfoHexagonIcon />
              </button>
            </div>
            <div className={styles.flex}>
              <p className={styles.top__textMuted}>(${balance.toLocaleString('en-US', { minimumFractionDigits: 2 })})</p>
              <p>
                <span className={styles.top__textWhite}>16.5&nbsp;</span>
                <span className={styles.top__textYellow}>HNY</span>
              </p>
            </div>
          </div>

          <div className={styles.table}>
            <div className={styles.table__row}>
              <span>NFT</span>
              <span>Quantity</span>
              <span>Daily rewards</span>
              <span>Current rewards</span>
              <span></span>
            </div>
            {data.map((d, i) => {
              return (
                <div className={styles.table__row} key={i}>
                  <span>{d.type}</span>
                  <span>{d.quantity}</span>
                  <span>{d.dailyRewards} HNY (${(d.dailyRewards * 100).toLocaleString('en-US', { minimumFractionDigits: 2 })})</span>
                  <span>{d.currentRewards} HNY (${(d.currentRewards * 100).toLocaleString('en-US', { minimumFractionDigits: 2 })})</span>
                  <span>Claim</span>
                </div>
              );
            })}
          </div>

          <button className={styles.claim}>Claim all</button>
        </div>

        {isModalOpen &&
          <div className={styles.modal}>
            <button className={styles.modal__close} onClick={() => setModalState(false)} >
              <Icon color="#72799B" icon="ep:close" height={24} />
            </button>
            <p className={styles.modal__heading}>Do I get taxed when I claim my rewards?</p>
            <p className={styles.modal__body}>Most DaaS models implement a claim tax, but we feel that compounding one's profits should not be taxed. Therefore, we have moved the tax over to when a user goes to sell their $HNY. The sale tax will be 15%, meaning that whenever a user sells their $HNY tokens on a Decentralized Exchange (DEX), slippage must be set to at least 15%.</p>
            <p className={styles.modal__heading}>How much does it cost to mint an NFT?</p>
            <p className={styles.modal__body}>There are three different tiers of NFTs, all of which can be obtained by minting with $HNY tokens. Each tier offers important stylistic differences as well as differences in yield. </p>
          </div>
        }

      </div>
    </>
  );
}
