// import  from '../../node_modules/cryptocurrency-icons/svg/icon/btc.svg';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import { ReactComponent as AaveIcon } from '../../../node_modules/cryptocurrency-icons/svg/icon/aave.svg';
import { ReactComponent as BitcoinIcon } from '../../../node_modules/cryptocurrency-icons/svg/icon/btc.svg';
import { ReactComponent as PolkadotIcon } from '../../../node_modules/cryptocurrency-icons/svg/icon/dot.svg';
import { ReactComponent as EthereumIcon } from '../../../node_modules/cryptocurrency-icons/svg/icon/eth.svg';
import { ReactComponent as PolygonIcon } from '../../../node_modules/cryptocurrency-icons/svg/icon/matic.svg';
import { ReactComponent as SolanaIcon } from '../../../node_modules/cryptocurrency-icons/svg/icon/sol.svg';
import styles from './Tracker.module.scss';

export default function Tracker() {
  return (
    <>
      <div className={styles.base}>
        <div className={styles.headers}>
          <span>Name / ticker</span>
          <span>Initial investment (USD)</span>
          <span>Average entry price (USD)</span>
          <span>Current investment value (USD)</span>
          <span>Current price (USD)</span>
          <span>Daily income</span>
          <span>P&L (%)</span>
        </div>

        <Bar color="green" icon="btc" />
        <Bar color="red" icon="matic" />
        <Bar color="green" icon="sol" />
        <Bar color="green" icon="aave" />
        <Bar color="green" icon="eth" />

      </div>
    </>
  );
}

function Bar({ color, icon }) {
  const data = [
    { "name": "Page A", "uv": 4000, "pv": 2400, "amt": 2400 },
    { "name": "Page B", "uv": 3000, "pv": 1398, "amt": 2210 },
    { "name": "Page C", "uv": 2000, "pv": 9800, "amt": 2290 },
    { "name": "Page D", "uv": 2780, "pv": 3908, "amt": 2000 },
    { "name": "Page E", "uv": 1890, "pv": 4800, "amt": 2181 },
    { "name": "Page F", "uv": 2390, "pv": 3800, "amt": 2500 },
    { "name": "Page G", "uv": 3490, "pv": 4300, "amt": 2100 }
  ];

  // Graph colors
  const style = color === 'green' ? styles.textGreen : styles.textRed;
  const graphColor = color === 'green' ? '#ffb444' : '#82b4ff';
  const graphId = color === 'green' ? 'colorPv' : 'colorQv';

  // Icon
  let svg = <BitcoinIcon />;
  if (icon === 'btc') {
    svg = <BitcoinIcon />;
  } else if (icon === 'eth') {
    svg = <EthereumIcon />;
  } else if (icon === 'matic') {
    svg = <PolygonIcon />;
  } else if (icon === 'sol') {
    svg = <SolanaIcon />;
  } else if (icon === 'dot') {
    svg = <PolkadotIcon />;
  } else if (icon === 'aave') {
    svg = <AaveIcon />;
  }

  return (
    <div className={styles.container}>
      <div>
        {svg}
        <p>{icon}</p>
      </div>
      <div>
        <ResponsiveContainer width="80%" height="60%">
          <AreaChart width={730} height={250} data={data}>
            <defs>
              <linearGradient id={graphId} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={graphColor} stopOpacity={0.8} />
                <stop offset="95%" stopColor={graphColor} stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area type="monotone" dataKey="pv" stroke={graphColor} fillOpacity={1} fill={`url(#${graphId})`} />
          </AreaChart>
        </ResponsiveContainer>
        <div>
          <span>~ <span className={style}>$</span>38,965,30</span>
          <span><span className={style}>15%</span></span>
        </div>
      </div>
      <div>
        <span><span className={style}>$</span>389,675,005</span>
      </div>
      <div><span><span className={style}>$</span>111,675,000</span></div>
      <div><span><span className={style}>$</span>89,635,002</span></div>
      <div><span><span className={style}>$</span>559,675,000</span></div>
      <div><span><span className={style}>15%</span></span></div>
    </div>
  );
}
