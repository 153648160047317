import { Icon } from '@iconify/react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import styles from './Growth.module.scss';

export default function Growth() {
  const data = [
    { name: 'DEC 14', uv: 1600 },
    { name: 'DEC 15', uv: 2000 },
    { name: 'DEC 16', uv: 1900 },
    { name: 'DEC 17', uv: 2400 },
  ];

  return (
    <>
      <div className={styles.base}>
        <div className={styles.wrapper}>
          <div className={styles.top}>
            <div className={styles.titles}>
              <button>Treasury growth</button>
              <button>Market cap</button>
            </div>
            <Icon icon="charm:screen-maximise" color="#ffffff" />
          </div>

          <div className={styles.graph}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data}>
                <Line
                  dataKey="uv"
                  stroke="#a392db"
                  strokeWidth={2}
                  // type="monotone"
                  dot={{
                    strokeWidth: 0,
                    r: 4.5,
                    fill: '#ffffff',
                  }}
                />
                <CartesianGrid stroke="#cee2ff0D" />
                <XAxis
                  axisLine={false}
                  dataKey="name"
                  dy={20}
                  padding={{ left: 20, right: 20 }}
                  stroke="#72799bb3"
                  tickLine={false}
                />
                <YAxis
                  axisLine={false}
                  domain={['dataMin', 'dataMax']}
                  dx={-20}
                  scale="linear"
                  stroke="#72799bb3"
                  tickLine={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className={styles.graph__legend}>
            <div></div>
            <span>Treasure line</span>
          </div>
        </div>
      </div>
    </>
  );
}
