import { Icon } from '@iconify/react';
import clsx from 'clsx';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import styles from './Investments.module.scss';

export default function Investments() {
  const data = [
    { "name": "BTC", "uv": 55, "pv": 100 },
    { "name": "MATIC", "uv": 80, "pv": 35 },
    { "name": "OHM", "uv": 20, "pv": 90 },
    { "name": "TIME", "uv": 80, "pv": 90 },
    { "name": "ETH", "uv": 90, "pv": 15 },
  ];

  return (
    <>
      <div className={styles.base}>
        <div className={styles.wrapper}>

          <div className={styles.top}>
            <div className={styles.label}>
              <span className={styles.label__main}>Top 5 treasury investments</span>
              <span>&nbsp;</span>
              <span className={styles.label__muted}>(USD value)</span>
            </div>
            <Icon icon="charm:screen-maximise" color="#ffffff" />
          </div>

          <div className={clsx(styles.graph, 'recharts__barChart')}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={data}>
                <CartesianGrid stroke="#cee2ff0D" vertical={false} />
                <XAxis
                  axisLine={false}
                  dataKey="name"
                  dy={20}
                  stroke="#72799bb3"
                  tickLine={false}
                />
                <YAxis
                  axisLine={false}
                  dx={-20}
                  scale="linear"
                  stroke="#72799bb3"
                  tickLine={false}
                />
                <Bar barSize={6} dataKey="pv" fill="#c0a9ff" radius={[10, 10, 0, 0]} />
                <Bar barSize={6} dataKey="uv" fill="#d8ff83" radius={[10, 10, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className={styles.graph__legend}>
            <div></div>
            <span>Initial value</span>
            <div></div>
            <span>Current value</span>
          </div>
        </div>
      </div>
    </>
  );
}
