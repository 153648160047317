import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import { ReactComponent as BadgeLineBlue } from '../../assets/images/badge-line-blue.svg';
import { ReactComponent as BadgeLineGreen } from '../../assets/images/badge-line-green.svg';
import { ReactComponent as BadgeLinePeach } from '../../assets/images/badge-line-peach.svg';
import { ReactComponent as BadgeLinePurple } from '../../assets/images/badge-line-purple.svg';
import styles from './Badges.module.scss';

export default function Badges() {
  const data = [
    { "name": "Page A", "uv": 4000, "pv": 2400, "amt": 2400 },
    { "name": "Page B", "uv": 3000, "pv": 1398, "amt": 2210 },
    { "name": "Page C", "uv": 2000, "pv": 9800, "amt": 2290 },
    { "name": "Page D", "uv": 2780, "pv": 3908, "amt": 2000 },
    { "name": "Page E", "uv": 1890, "pv": 4800, "amt": 2181 },
    { "name": "Page F", "uv": 2390, "pv": 3800, "amt": 2500 },
    { "name": "Page G", "uv": 3490, "pv": 4300, "amt": 2100 }
  ];

  return (
    <>
      <div className={styles.base}>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <p className={styles.leftHeading}>Hive</p>
            <div className={styles.graph}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart width={730} height={250} data={data}>
                  <defs>
                    <linearGradient id="main" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#ffb444" stopOpacity={0.8} />
                      <stop offset="95%" stopColor="#ffb444" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                  <Area type="monotone" dataKey="pv" stroke="#ffb444" fillOpacity={1} fill={`url(#main)`} />
                </AreaChart>
              </ResponsiveContainer>
            </div>
            <div className={styles.leftNumbers}>
              <p className={styles.leftPrice}>~ <span>$</span>32.30</p>
              <p className={styles.leftPercent}>15%</p>
            </div>
          </div>
          <div className={styles.right}>

            <div className={styles.row}>

              <div className={styles.badge}>
                <div className={styles.badgeWrapper}>
                  <div className={styles.badgeWrapper2}>
                    <span>30,128</span>
                    <span>Worker bees</span>
                    <span>Remaining</span>
                  </div>
                </div>
                <BadgeLineGreen />
              </div>

              <div className={styles.badge}>
                <div className={styles.badgeWrapper}>
                  <div className={styles.badgeWrapper2}>
                    <span>456</span>
                    <span style={{ color: '#82b4ff' }}>Guardian bees</span>
                    <span>Remaining</span>
                  </div>
                </div>
                <BadgeLineBlue />
              </div>
            </div>

            <div className={styles.row}>

              <div className={styles.badge}>
                <div className={styles.badgeWrapper}>
                  <div className={styles.badgeWrapper2}>
                    <span>125</span>
                    <span style={{ color: '#ffe2a9' }}>Queen bees</span>
                    <span>Remaining</span>
                  </div>
                </div>
                <BadgeLinePeach />
              </div>

              <div className={styles.badge}>
                <div className={styles.badgeWrapper}>
                  <div className={styles.badgeWrapper2}>
                    <span>30,709</span>
                    <span style={{ color: '#bfa9ff' }}>Total bees</span>
                    <span>Remaining</span>
                  </div>
                </div>
                <BadgeLinePurple />
              </div>

            </div>

          </div>
        </div>
      </div>
    </>
  );
}
